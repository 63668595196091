<script lang="ts" context="module">
	import { enforce } from "vest";
	import { RuleGenerator } from "../view-models";

	export const createRadioGroupFieldRules: RuleGenerator = function*(field) {
		if (field.required) {
			yield ["Bitte wählen Sie eine Option aus.", value => enforce(value).isNotEmpty()];
		}
	};
</script>

<script lang="ts">
	import slugify from "slugify";
	import { CustomFormField } from "../view-models";
	import { Form, FormField } from "../models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";

	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;
</script>

{#if field.values != null}
	<div class="field">
		<label class="label" for={field.name}>{field.label}</label>
		<div class="control">
			<div class="is-flex is-h-100">
				{#if !field.required}
					<input class="is-checkradio" id={field.name + "_null"} type="radio" name={field.name} value={null} bind:group={state.value}>
					<label for={field.name + "_null"}>Keine Auswahl</label>
				{/if}
				{#each field.values as value}
					<input class="is-checkradio" id={field.name + slugify(value)} type="radio" name={field.name} value={value} bind:group={state.value}>
					<label for={field.name + slugify(value)}>{value}</label>
				{/each}
			</div>
		</div>
		{#if field.hint && !state.hasErrors}
			<p class="help">{field.hint}</p>
		{/if}
		{#each state.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>
{/if}
