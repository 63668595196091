import component from "@wagich/svelte-tag";

import BlucMap from "./bluc-map.svelte";
new component({
	component: BlucMap,
	tagname: "bluc-map",
	attributes: ["longitude", "latitude", "zoom", "accesstoken", "style"]
});

import CustomForm from "./custom-form/custom-form.svelte";
new component({
	component: CustomForm,
	tagname: "custom-form",
	attributes: ["form-id", "visibility", "metadata"]
});

import Flickity from "flickity";
import { Accordion } from "./accordion";
let flickityElements = document.querySelectorAll("[data-carousel]");
for (let element of flickityElements) {
	new Flickity(element, {
		contain: true,
		pageDots: false,
		arrowShape: "m99.69,41.51c-.82-5.38-2.41-10.58-4.72-15.4-2.27-5.1-5.54-9.61-9.78-13.43-1.99-1.6-3.98-3-5.91-4.16-3.52-2.18-7.19-4.18-8.99-5.02-.47-.22-.96-.33-1.47-.33-1.32,0-2.54.77-3.11,1.98-.38.82-.41,1.75-.1,2.62.32.82.93,1.49,1.73,1.9,1.47.71,4.17,2.2,6.93,3.92,2.22,1.34,4.41,2.85,6.44,4.44,3.29,3.13,5.84,6.84,7.59,11.07,1.96,4.27,3.26,8.77,3.84,13.34,1.29,13.79-4.54,27.01-15.64,35.41-7.42,6.33-16.89,9.82-26.67,9.82-3.69,0-7.4-.5-11.02-1.48-2.62-.76-5.11-1.63-7.83-2.73-2.33-.98-4.71-2.21-7.06-3.64-6.73-4.32-11.72-10.88-14.05-18.5-2.5-7.62-2.38-15.98.36-23.56,3.27-9.49,9.14-17.74,16.95-23.83,6.66-4.78,14.48-7.3,22.61-7.3,1.67,0,3.34.11,5.54.36,1.64-.02,3.02-1.26,3.22-2.87.11-.82-.13-1.64-.73-2.44-.61-.67-1.37-1.07-2.07-1.12-2.2-.35-4.53-.53-6.93-.53-9.29,0-18.22,2.81-25.84,8.13C13.78,15.04,6.86,24.35,2.98,35.05c-3.56,9.2-3.94,19.47-1.07,28.89,2.84,9.61,9.05,17.89,17.5,23.32,2.61,1.61,5.4,3.01,8.27,4.16,2.72,1.14,5.54,2.15,8.66,3.09,4.37,1.17,8.8,1.76,13.16,1.76l1-.09c11.51-.23,22.6-4.46,31.3-11.94,12.9-10.07,19.75-26.43,17.88-42.73Zm-70.48,1.57c-.96,1.21-1.4,2.73-1.23,4.55.23.93.66,1.79,1.37,2.69,1.74,1.82,3.74,3.47,5.92,4.9,8.05,5.68,21.8,13.85,25.87,16.07.6.36,1.21.54,1.79.54l1-.04v-.15c.71-.23,1.33-.7,1.74-1.36.92-1.51.51-3.45-.92-4.43-2.6-1.8-9.05-6.36-15.36-10.96-5.55-4.09-9.32-6.99-11.51-8.85,3.96-3.79,8.09-7.35,12.32-10.61,4.77-3.96,10.05-7.88,15.71-11.65,1.49-.38,2.55-1.73,2.55-3.28,0-.9-.35-1.75-.99-2.39-.64-.64-1.49-.99-2.39-.99h-.52l-.5.12-.23.13c-9,5.01-17.57,10.7-25.43,16.9-3.37,2.49-6.46,5.46-9.16,8.83Z",
	});
}

import { Fancybox } from "@fancyapps/ui";
Fancybox.bind(".is-zoomable", {
	groupAttr: "data-gallery"
});

for (let details of document.querySelectorAll("details")) {
	try {
		new Accordion(details);
	} catch (e) {
		console.log(e);
	}
}

for (let onpageNavLink of document.querySelectorAll(".nav-item--onpage > a")) {
	onpageNavLink.addEventListener("click", () => {
		let menustateToggle = document.querySelector<HTMLInputElement>("#menustate");
		if (menustateToggle == null) {
			return;
		}
		menustateToggle.checked = false;
	});
}

for (let anchor of document.querySelectorAll("#restaurantmenuNav > a")) {
	anchor.addEventListener("click", function (e) {
		e.preventDefault();

		let href = anchor.getAttribute("href");
		if (href == null || href.length <= 1) {
			return;
		}

		let target = document.querySelector(href);
		if (target == null) {
			return;
		}

		let fixedHeaderHeight = document.querySelector("#restaurantmenuNav")?.clientHeight ?? 0;
		let targetTop = target.getBoundingClientRect().y + window.scrollY;

		window.scrollTo({
			top: targetTop - fixedHeaderHeight,
			behavior: "smooth"
		});
	});
}

for (let anchor of document.querySelectorAll("a[href^='bluc:open-form']")) {
	anchor.addEventListener("click", function (e) {
		e.preventDefault();

		let href = anchor.getAttribute("href");
		if (href == null) {
			return;
		}

		let formId = new URL(href).searchParams.get("form-id");
		if (formId == null) {
			return;
		}

		let form = document.getElementById(`form-${formId}`);
		if (form == null) {
			form = document.createElement("custom-form");
			form.id = `form-${formId}`;
			form.setAttribute("form-id", formId);
			document.body.appendChild(form);
		}

		form.removeAttribute("visibility");
		form.setAttribute("visibility", "visible");
	});
}

document.documentElement.classList.add("is-loaded");
