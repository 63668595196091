export interface Form {
	transactionId: string;
	formId: string;
	title: string;
	textHtml: string;
	fields: FormField[];
	submitButtonText: string;
	successMessageHtml: string;
	errorMessageHtml: string;
	privacyPolicyUrl: string;
}

export interface FormField {
	fieldType: string;
	name: string;
	type?: string;
	autofill?: null;
	label: string;
	hint: null;
	placeholder: null;
	width: FieldWidth;
	required: boolean;
	values?: string[];
}

export enum FieldWidth {
	Full = "full",
	ThreeQuarters = "threeQuarters",
	Half = "half",
	OneQuarter = "oneQuarter"
}

export enum TextFieldType {
	Text = "text",
	Number = "number",
	Email = "email",
	Phone = "tel",
}
