<script lang="ts" context="module">
	import { enforce } from "vest";
	import { RuleGenerator } from "../view-models";

	export const createCheckboxFieldRules: RuleGenerator = function* (field) {
		if (field.required) {
			yield ["Dieses Feld muss aktiviert sein, um das Formular abschicken zu können.", value => enforce(value).isBoolean().equals(true)];
		}
	}
</script>

<script lang="ts">
	import { CustomFormField } from "../view-models";
	import { Form, FormField } from "../models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";
	
	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;
</script>

<div class="is-h-100">
	<div class="field">
		<div class="control">
			<input class="is-checkradio" id={field.name} type="checkbox" name={field.name} bind:checked={state.value} />
			<label for={field.name} class:has-text-danger={state.hasErrors}>{field.label}</label>
			{#if field.hint && !state.hasErrors}
				<p class="help">{field.hint}</p>
			{/if}
			{#each state.errors as error}
				<p class="help is-danger">{error}</p>
			{/each}
		</div>
	</div>
</div>
