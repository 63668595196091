<script lang="ts">
	import { onMount } from "svelte";
	import mapboxgl from "mapbox-gl";
	import IntersectionObserver from "svelte-intersection-observer";

	export let accesstoken: string;
	export let styleurl: string;
	export let latitude: number;
	export let longitude: number;
	export let zoom: number;

	let mapElement: HTMLElement;
	let isInView: boolean;

	onMount(() => {
		mapboxgl.accessToken = accesstoken;
		const map = new mapboxgl.Map({
			container: mapElement,
			style: styleurl,
			center: [longitude, latitude],
			zoom: zoom,
			scrollZoom: false
		});

		map.addControl(new mapboxgl.NavigationControl());

		const marker = new mapboxgl.Marker({
				color: "#780928",
			})
			.setLngLat([longitude, latitude])
			.addTo(map);
	});
</script>

<IntersectionObserver element={mapElement} bind:intersecting={isInView}>
	<div bind:this={mapElement} class:is-inview={isInView} />
</IntersectionObserver>

<style lang="scss">
	@import "mapbox-gl/dist/mapbox-gl.css";

	:global(.bluc-map__fallback) {
		display: none;
	}

	:global(.bluc-map) {
		position: relative;
		width: 100%;
		padding-bottom: 80%;
		z-index: 0;
	}

	:global(bluc-map) {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}

	:global(.mapboxgl-map) {
		height: 100%;
	}
</style>
