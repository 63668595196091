export class Accordion {
	element: HTMLDetailsElement;
    summary: HTMLElement;
    content: HTMLElement;
    animation: Animation | null;
    isClosing: boolean;
    isExpanding: boolean;

	constructor(el: HTMLDetailsElement) {
		this.element = el;

		let summary = el.querySelector<HTMLElement>("summary");
		let content = el.querySelector<HTMLElement>(":scope > :not(summary)");
		if (summary == null) {
			throw new Error("Specified details element contains no summary element.");
		}
		if (content == null) {
			throw new Error("Specified details element contains no content.");
		}

		this.summary = summary;
		this.content = content;
		this.animation = null;
		this.isClosing = false;
		this.isExpanding = false;

		this.summary.addEventListener("click", this.onClickBound);
	}

	open() {
		this.element.style.height = `${this.element.offsetHeight}px`;
		this.element.open = true;
		window.requestAnimationFrame(() => this.expand());
	}

	close() {
		this.shrink();
	}

	private onClickBound = this.onClick.bind(this);
	private onClick(e: MouseEvent) {
		e.preventDefault();

		this.element.style.overflow = "hidden";
		if (this.isClosing || !this.element.open) {
			this.open();
		} else if (this.isExpanding || this.element.open) {
			this.close();
		}
	}

	private shrink() {
		this.isClosing = true;

		const startHeight = `${this.element.offsetHeight}px`;
		// Calculate the height of the summary
		const endHeight = `${this.summary.offsetHeight}px`;

		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.element.animate({
			height: [startHeight, endHeight]
		}, {
			duration: 400,
			easing: "ease-out"
		});

		this.animation.onfinish = () => this.onAnimationFinish(false);
		this.animation.oncancel = () => this.isClosing = false;
	}

	private expand() {
		this.isExpanding = true;
		const startHeight = `${this.element.offsetHeight}px`;
		// Calculate the open height of the element (summary height + content height)
		const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;

		if (this.animation != null) {
			this.animation.cancel();
		}

		this.animation = this.element.animate({
			height: [startHeight, endHeight]
		}, {
			duration: 400,
			easing: "ease-out"
		});
		this.animation.onfinish = () => this.onAnimationFinish(true);
		this.animation.oncancel = () => this.isExpanding = false;
	}

	private onAnimationFinish(isOpen: boolean) {
		this.element.open = isOpen;

		this.animation = null;
		this.isClosing = false;
		this.isExpanding = false;

		this.element.style.height = "";
		this.element.style.overflow = "";
	}
}
