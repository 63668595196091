<script lang="ts">
	import { Form, FormField } from "../models";
	import { CustomFormField } from "../view-models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";

	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;
</script>

<h3 class="title is-5 mt-2">{field.label}</h3>
