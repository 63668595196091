<script lang="ts" context="module">
	import { enforce } from "vest";
	import { Form, FormField, TextFieldType } from "../models";
	import { RuleGenerator } from "../view-models";

	export const createTextFieldRules: RuleGenerator = function* (field) {
		if (field.required) {
			yield ["Bitte füllen Sie dieses Feld aus.", value => {
				enforce(value).isNotEmpty();
			}];
		}
		if (field.type === TextFieldType.Email) {
			yield ["Bitte geben Sie eine gültige E-Mail-Adresse an.", value => {
				enforce(value).isNotEmpty().matches(/.+\@.+\..+/);
			}];
		}
		if (field.type === TextFieldType.Number) {
			yield ["Bitte geben Sie eine Zahl ein.", value => {
				enforce(value).isNumeric();
			}];
		}
	}
</script>

<script lang="ts">
	import { CustomFormField } from "../view-models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";
	
	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;

	function getInputType() {
		switch (field.type) {
			case "dateTime":
				return "datetime-local";
		}
		return field.type ?? "text";
	}
</script>

<div class="field">
	<label class="label" for={field.name}>{field.label}</label>
	<div class="control">
		<input id={field.name} class="input" type={getInputType()} placeholder={field.placeholder} autocomplete={field.autofill} on:input={e => state.value = e.target.value} />
	</div>
	{#if field.hint && !state.hasErrors}
		<p class="help">{field.hint}</p>
	{/if}
	{#each state.errors as error}
		<p class="help is-danger">{error}</p>
	{/each}
</div>
