<script lang="ts" context="module">
	import { enforce } from "vest";
	import { Form, FormField, TextFieldType } from "../models";
	import { RuleGenerator } from "../view-models";

	export const createMultilineTextFieldRules: RuleGenerator = function* (field) {
		if (field.required) {
			yield ["Bitte füllen Sie dieses Feld aus.", value => enforce(value).isNotEmpty()];
		}
	}
</script>

<script lang="ts">
	import { CustomFormField } from "../view-models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";
	
	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;
</script>

<div class="field">
	<label class="label" for={field.name}>{field.label}</label>
	<div class="control">
		<textarea id={field.name} class="textarea" placeholder={field.placeholder} autocomplete={field.autofill} bind:value={state.value}></textarea>
	</div>
	{#if field.hint && !state.hasErrors}
		<p class="help">{field.hint}</p>
	{/if}
	{#each state.errors as error}
		<p class="help is-danger">{error}</p>
	{/each}
</div>
