<script lang="ts">
type TState = $$Generic;

import { FormField, FieldWidth, Form } from "./models";
import { ValidatingStore, ValidatingStoreItem } from "./validating-store";
import { FieldRegistry } from "./field-registry";

//const serverError = (fieldName: string) =>
//	function (value: any) {
//		let hasError = this.serverState?.hasOwnProperty(fieldName) ?? false;
//		return !hasError;
//	};

	export let store: ValidatingStore<TState>;
	export let form: Form;
	export let field: FormField;

	function* getClassNames() {
		yield "column";
		yield "custom-form__block";

		switch (field.width) {
			case FieldWidth.ThreeQuarters:
				yield "is-three-quarters";
				break;
			case FieldWidth.Half:
				yield "is-half";
				break;
			case FieldWidth.OneQuarter:
				yield "is-one-quarter";
				break;
			case FieldWidth.Full:
			default:
				yield "is-full";
				break;
		}
	}
</script>

<div class={Array.from(getClassNames()).join(" ")}>
	<svelte:component this={FieldRegistry.getComponent(field.fieldType)} {form} {store} {field} bind:state={$store[field.name]} />
</div>
